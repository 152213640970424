<template>
  <div class="guide-container line-height-1-6">
    <h1 class="second-colour">
      How To Buy Truth GPT Crypto Coin
    </h1>

    <img
      src="@/assets/imgs/Companies/TruthGPT/truth-gpt-purple-hero-banner.webp"
      alt="truth-gpt-blue-and-white-logo"
    />

    <br />

    <!-- <p class="colour-5"> -->
    <p class="fourth-colour">
      An obvious, but important disclaimer: Information here is for
      educational purposes only and is not financial advice. Do your own
      research before making any investments, as the value of cryptocurrencies
      can fluctuate.
    </p>

    <br />

    <h2 class="second-colour">
      The steps 👞👞
    </h2>
    <p>Ok, let's get into why you came here:</p>
    <br />

    <ol class="line-height-1-6">
      <!-- Start of ordered list -->

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li>
        Install the TrustWallet crypto wallet on your iPhone or Android. You can
        download it
        <a href="https://trustwallet.com/download/" target="_blank">here.</a>
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Add $BNB (Binance coin) to your wallet by transferring from an exchange,
        Eg. Binance or Coinbase. Or you can purchase the coin directly. If you
        don't have a Binance account you can create one
        <router-link to="/how-to-open-a-binance-account" target="_blank"
          >here.</router-link
        >
      </li>

      <!-- Step item ---------------------------------------------->
      <!-- -------------------------------------------------------->
      <li class="margin-top-40px">
        Connect your TrushWallet and swap $BNB (Binance coin) for $TRUTH (Truth
        GPT coin) on PancakeSwap

        <a
          href="https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x2abdb5903171071ac29cc0779d7efdf0faf14228"
          target="_blank"
          >here.</a
        >
      </li>

      <!-- End of Steps ordered list -->
    </ol>
    <br />
    <p>
      So what the hell does that all mean?! If that's what you're thinking,
      check out the video below to see the process in action.
    </p>

    <br />

    <iframe
      width="800"
      height="480"
      src="https://www.youtube.com/embed/OSzlzW9BIYA"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      style="width:100%; max-width: 800px;"
    ></iframe>

    <br />
    <br />

    <h2 class="second-colour">
      Wait, none of that worked!! 😡
    </h2>

    <p>
      As with anything in the world of crypto, things can change at the drop of
      a hat. If the steps above don't work, check out the Truth GPT site for the
      latest info
      <a href="https://truthgpt.one/#section_598371265" target="_blank">here</a
      >.
    </p>

    <br />
    <br />
    <h2 class="second-colour">
      So what the heck is Truth GPT coin? 🤷
    </h2>

    <img
      src="@/assets/imgs/Companies/TruthGPT/truth-gpt-Elon-Musk-Tweet.webp"
      alt="elon-musk-tweet-about-needing-truth-gpt"
    />

    <p>
      I was also stumped on what it is. Is it an AI bot? a currency? a Musk
      quote?
    </p>

    <p>
      To put it simply. Elon Musk tweeted a new word into existence recently -
      TruthGPT. This got everyone scratching their heads and wondering what the
      heck it is and where they can get their hands on it! Some folks are
      calling it the next ChatGPT AI product because it was coined just a few
      weeks after ChatGPT's release.
    </p>

    <p>
      But hold up a sec, TruthGPT isn't some fancy AI tool that Elon Musk
      invented. Nope, he just made a suggestion on Twitter after someone pointed
      out that ChatGPT categorized him and Donald Trump as controversial celebs.
      Actually, TruthGPT is a cryptocurrency.
    </p>

    <br />
    <br />
    <h2 class="second-colour">
      Where to buy Truth GPT coin? 🛒
    </h2>

    <p>
      You can snag yourself some TruthGPT in a bunch of different ways, but the
      easiest option is to hit up CoinBrain Trade
      <a
        href="https://coinbrain.com/coins/bnb-0x2abdb5903171071ac29cc0779d7efdf0faf14228"
        target="_blank"
        >here</a
      >. This platform can tell you the best exchange to use, and it's fast and
      cheap too!
    </p>

    <p>
      If you're not into CoinBrain, you've got options - TruthGPT is up for
      grabs on other exchanges like
      <a
        href="https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x2abdb5903171071ac29cc0779d7efdf0faf14228"
        target="_blank"
        >PancakeSwap</a
      >. Or just follow the steps above if you're unsure.
    </p>

    <br />
    <br />
    <h2 class="second-colour">
      What is the price of Truth GPT crypto? $0.0003832 USD 📈
    </h2>

    <img
      src="@/assets/imgs/Companies/TruthGPT/TruthGPT-to-USD-price-chart.webp"
      alt="truth-gpt-to-usd-price-chart"
    />

    <p class="line-height-1-6">
      As of writing the price of Truth GPT is at a glorious
      <strong> $0.0003832 USD.</strong> You can check out latest price over at
      coinmarketcap
      <a href="https://coinmarketcap.com/currencies/truthgpt/" target="_blank"
        >here</a
      >.
    </p>

    <br />
    <br />
    <h2 class="second-colour">
      Other newbie guides to feast your eyes upon 👀
    </h2>

    <p>
      If you're super fresh to crypto, this is the guide for you to get your
      feet wet ->
      <router-link to="/how-to-open-a-binance-account" target="_blank"
        >How to open a Binance account</router-link
      >
    </p>

    <p>
      And once you've got that Binance account set up, next steps are here ->
      <router-link
        to="/how-to-buy-bitcoin-on-binance-with-bank-transfer"
        target="_blank"
        >How To Buy Bitcoin On Binance With Bank Transfer</router-link
      >
    </p>
  </div>
</template>
